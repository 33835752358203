const enum ZPosition {
  WorldMapBase = 0,
  CollisionLayer = .0011,
  WorldMapAbove = .001,
  Npc = .0001,
  WorldMapStaticObject = .0002,
  DynamicObjectBelow = .0004,
  Player = .0005,
  DynamicObjectAbove = .0006
}
export  default ZPosition;