import * as THREE from "three";
import { Mesh } from "three";
import { Subject, takeUntil } from "rxjs";
import { CollisionPlane } from "./CollisionPlane";
import { Position, Size } from "./Base";
import ObjectCategory from "../../../../core/enums/ObjectCategory";
import ZPosition from "../../../../core/enums/ZPosition";

export class MapObject {
  plane: Mesh;
  collision?: CollisionPlane;

  constructor(mapObjectDto: MapObjectDto, playerYIndex$: Subject<number>, unsubscribeNotifier$: Subject<void>) {
    mapObjectDto.yOffset = mapObjectDto.yOffset ?? 0;

    const texture = new THREE.TextureLoader().load(`./assets/objects/${mapObjectDto.category}/${mapObjectDto.id}.png`);
    texture.magFilter = THREE.NearestFilter;

    const geometry = new THREE.PlaneGeometry(mapObjectDto.width, mapObjectDto.height);
    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });

    this.plane = new THREE.Mesh(geometry, material);
    this.plane.position.set(mapObjectDto.x, mapObjectDto.y, ZPosition.DynamicObjectBelow);

    if (mapObjectDto.isCollidable) {
      const collisionY = mapObjectDto.y - (mapObjectDto.height / 4) - mapObjectDto.yOffset;
      this.collision = new CollisionPlane({ width: mapObjectDto.width, height: mapObjectDto.height / 2, x: mapObjectDto.x, y: collisionY });
    }

    playerYIndex$
      .pipe(takeUntil(unsubscribeNotifier$))
      .subscribe(playerYIndex => {
        if (playerYIndex > this.plane.position.y + mapObjectDto.yOffset! && this.plane.position.z !== ZPosition.DynamicObjectAbove) {
          this.plane.position.setZ(ZPosition.DynamicObjectAbove);
        } else if (playerYIndex < this.plane.position.y + mapObjectDto.yOffset! && this.plane.position.z !== ZPosition.DynamicObjectBelow) {
          this.plane.position.setZ(ZPosition.DynamicObjectBelow);
        }
      });
  }
}

interface MapObjectDto extends Position, Size {
  category: ObjectCategory;
  id: string | number;
  isCollidable?: boolean;
  yOffset?: number;
}
