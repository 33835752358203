class CharacterAnimations {
  static readonly MovementIndexes = 24;

  static readonly IdlingCycles = {
    Up: [6, 7, 8, 9, 9, 9, 10, 11, 6, 7, 8, 9, 9, 9, 10, 11, 10, 11],
    Down: [18, 19, 22, 23, 22, 23, 18, 18, 18, 19, 20, 21, 22, 23, 18, 18, 18, 19, 20, 21],
    Left: [12, 13, 16, 17, 16, 17, 12, 12, 12, 13, 14, 15, 16, 17, 12, 12, 12, 13, 14, 15],
    Right: [0, 1, 4, 5, 4, 5, 0, 0, 0, 1, 2, 3, 4, 5, 0, 0, 0, 1, 2, 3]
  }

  static readonly RunningCycles = {
    Up: [6, 7, 8, 9, 10, 11],
    Down: [18, 19, 20, 21, 22, 23],
    Left: [12, 13, 14, 15, 16, 17],
    Right: [0, 1, 2, 3, 4, 5]
  }

  static readonly ReadingIndexes = 18;
  static readonly ReadingCycles = [0, 1, 0, 1, 4, 5, 6, 10, 11, 0, 1, 6, 7, 0, 1, 2, 3, 3, 3, 4, 5, 6, 7, 0, 1, 0, 1, 6, 10, 0, 1, 11, 12, 13, 14, 15, 16, 17];
}
export default CharacterAnimations;