import * as THREE from "three";
import { CollisionPlane } from "./CollisionPlane";
import Direction from "../../../../core/enums/Direction";
import Coordinate from "../../../../core/enums/Coordinate";

class Movement {
    collisionPlane: CollisionPlane;
    interactionRange: CollisionPlane;
    speed: number = 0; // might make protected

    constructor(collisionPlane: CollisionPlane, interactionRange?: CollisionPlane) {
        this.collisionPlane = collisionPlane;
        this.interactionRange = interactionRange ?? new CollisionPlane({
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        });
    }

    protected determineCoordinate(direction: Direction): Coordinate {
        switch (direction) {
            case Direction.Up:
                return Coordinate.Y;
            case Direction.Down:
                return Coordinate.Y;
            case Direction.Right:
                return Coordinate.X;
            case Direction.Left:
                return Coordinate.X;
        }
    }

    protected newPosition(direction: Direction, position: THREE.Vector3): number {
        switch (direction) {
            case Direction.Up:
                return position.y + this.speed;
            case Direction.Down:
                return position.y - this.speed;
            case Direction.Right:
                return position.x + this.speed;
            case Direction.Left:
                return position.x - this.speed;
        }
    }

    protected collisionByDirection(direction: Direction, collision: CollisionPlane): boolean {
        switch (direction) {
            case Direction.Up:
                return collision.position(Coordinate.Y) > this.collisionPlane.position(Coordinate.Y);
            case Direction.Down:
                return collision.position(Coordinate.Y) < this.collisionPlane.position(Coordinate.Y);
            case Direction.Right:
                return collision.position(Coordinate.X) > this.collisionPlane.position(Coordinate.X);
            case Direction.Left:
                return collision.position(Coordinate.X) < this.collisionPlane.position(Coordinate.X);
        }
    }

    protected collisionModifier(direction: Direction): number {
        const dimensions = this.collisionPlane.size();
        const buffer = this.speed / 2
        switch (direction) {
            case Direction.Up:
                return (dimensions.height / -2) - this.collisionPlane.yOffset - buffer;
            case Direction.Down:
                return (dimensions.height / 2) - this.collisionPlane.yOffset + buffer;
            case Direction.Right:
                return (dimensions.width / -2) - buffer;
            case Direction.Left:
                return (dimensions.width / 2) + buffer;
        }
    }

    protected detectCollision(collision: CollisionPlane, direction: Direction, collisionAction: () => boolean, interactionAction: Function = () => null): boolean {
        this.collisionPlane.detectWorld();
        if (this.interactionRange) this.interactionRange.detectWorld();
        collision.detectWorld();

        if (collision.isInteractable() && this.interactionRange && this.interactionRange.intersects(collision)) {
            interactionAction();
        }
        if (this.collisionPlane.intersects(collision) && this.collisionByDirection(direction, collision)) {
            return collisionAction();
        }
        return false;
    };
}
export default Movement;