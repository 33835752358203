import * as THREE from "three";
import { Mesh, PlaneGeometry } from "three";
import { OBB } from 'three/examples/jsm/math/OBB';
import { Position, Size } from "./Base";
import IsProduction from "../../../../core/utils/IsProduction";
import Coordinate from "../../../../core/enums/Coordinate";
import Direction from "../../../../core/enums/Direction";
import ZPosition from '../../../../core/enums/ZPosition';

export class CollisionPlane {
  mesh: Mesh;
  visibilityMesh?: Mesh;
  yOffset: number;

  constructor(collisionPlaneDto: CollisionPlaneDto) {
    collisionPlaneDto.isInteractable = collisionPlaneDto.isInteractable ?? false;
    collisionPlaneDto.yOffset = collisionPlaneDto.yOffset ?? 0;
    collisionPlaneDto.color = collisionPlaneDto.color ?? 'red';

    this.yOffset = collisionPlaneDto.yOffset;

    const geometry = new THREE.PlaneGeometry(collisionPlaneDto.width, collisionPlaneDto.height);

    geometry.computeBoundingBox();

    this.mesh = new Mesh(geometry, new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 }));
    this.mesh.position.set(collisionPlaneDto.x, collisionPlaneDto.y + collisionPlaneDto.yOffset, ZPosition.Player);
    this.mesh.geometry.userData.obb = new OBB().fromBox3(this.mesh.geometry.boundingBox as THREE.Box3);
    this.mesh.userData.obb = new OBB();
    this.mesh.userData.isInteractable = collisionPlaneDto.isInteractable;

    if (!IsProduction()) {
      const material = new THREE.MeshBasicMaterial({ color: collisionPlaneDto.color, opacity: .2, transparent: true });
      this.visibilityMesh = new Mesh(geometry, material);

      this.visibilityMesh.position.set(collisionPlaneDto.x, collisionPlaneDto.y + collisionPlaneDto.yOffset, ZPosition.CollisionLayer);
    }
  }

  private moveX(x: number): void {
    this.mesh.position.setX(x);

    if (!IsProduction() && this.visibilityMesh) {
      this.visibilityMesh.position.setX(x);
    }
  }

  private moveY(y: number): void {
    this.mesh.position.setY(y + this.yOffset);

    if (!IsProduction() && this.visibilityMesh) {
      this.visibilityMesh.position.setY(y + this.yOffset);
    }
  }

  userData(): { [ key: string ]: any } {
    return this.mesh.userData;
  }

  size(): Size {
    return (this.mesh.geometry as PlaneGeometry).parameters;
  }

  position(coordinate: Coordinate): number {
    if (coordinate === Coordinate.Y) return this.mesh.position.y;
    if (coordinate === Coordinate.X) return this.mesh.position.x;
    return 0;
  }

  move(coordinate: Coordinate, position: number): void {
    if (coordinate === Coordinate.Y) this.moveY(position)
    if (coordinate === Coordinate.X) this.moveX(position)
  }

  intersects(collision: CollisionPlane): boolean {
    return this.userData().obb.intersectsOBB(collision.userData().obb)
  }

  detectWorld(): void {
    this.mesh.userData.obb.copy(this.mesh.geometry.userData.obb);
    this.mesh.userData.obb.applyMatrix4(this.mesh.matrixWorld);
  }

  collisionEdge(direction: Direction): number {
    const dimensions = this.size();
    switch (direction) {
      case Direction.Up:
        return this.position(Coordinate.Y) - (dimensions.height / 2);
      case Direction.Down:
        return this.position(Coordinate.Y) + (dimensions.height / 2);
      case Direction.Right:
        return this.position(Coordinate.X) - (dimensions.width / 2);
      case Direction.Left:
        return this.position(Coordinate.X) + (dimensions.width / 2);
    }
  }

  isInteractable(): boolean {
    return this.userData().isInteractable;
  }
}

export interface CollisionPlaneDto extends Position, Size {
  isInteractable?: boolean;
  yOffset?: number;
  color?: string;
}
