import * as THREE from "three";
import { PerspectiveCamera, Sprite} from "three";
import { Subject } from "rxjs";
import CharacterAnimations from "../../../../core/constants/CharacterAnimations";
import { CollisionPlane } from "./CollisionPlane";
import Menu from "./Menu";
import Movement from "./Movement";
import Coordinate from "../../../../core/enums/Coordinate";
import Direction from "../../../../core/enums/Direction";
import Speed from "../../../../core/enums/Speed";
import ZPosition from "../../../../core/enums/ZPosition";

class Player extends Movement{
  sprite: Sprite;
  playerYIndex$ = new Subject<number>();
  camera: PerspectiveCamera;
  menu: Menu;
  collisions: CollisionPlane[] = [];

  private isMoving = false;
  private isMenuOpen = false;
  private isSprintToggled = false;
  private direction = Direction.Up;
  private movementInterval?: NodeJS.Timer;

  private idlingTexture = new THREE.TextureLoader().load('./assets/characters/player/idling.png');
  private runningTexture = new THREE.TextureLoader().load('./assets/characters/player/running.png');
  private readingTexture = new THREE.TextureLoader().load('./assets/characters/player/reading.png');
  private idleTimer?: NodeJS.Timer;
  private idleDuration = 0;
  private animationInterval!: NodeJS.Timer;
  private currentRunningAnimationIndex = 0;

  private keyDownMap = new Map<Direction, boolean>();
  private keyDirectionMap = new Map<string, Direction>([
    ['ArrowUp', Direction.Up],
    ['W', Direction.Up],
    ['w', Direction.Up],
    ['ArrowDown', Direction.Down],
    ['S', Direction.Down],
    ['s', Direction.Down],
    ['ArrowLeft', Direction.Left],
    ['A', Direction.Left],
    ['a', Direction.Left],
    ['ArrowRight', Direction.Right],
    ['D', Direction.Right],
    ['d', Direction.Right],
  ]);

  constructor(camera: PerspectiveCamera, menu: Menu, unsubscribeNotifier$: Subject<void>) {
    const x = 0;
    const y = 2.5;
    const width = 1;
    const height = 2;
    const yOffset = -.75;
    super(new CollisionPlane({
        x: x,
        y: y,
        width: width,
        height: height / 4,
        yOffset: yOffset
      }),
      new CollisionPlane({
        x: x,
        y: y,
        width: 3 * width,
        height: 1.5 * height,
        yOffset: yOffset,
        color: 'blue'
      }));

    this.speed = Speed.Walk;
    this.sprite = new THREE.Sprite();
    this.sprite.geometry.computeBoundingBox();
    this.sprite.scale.set(width, height, 1);
    this.sprite.position.set(x, y, ZPosition.Player);

    this.camera = camera;
    this.menu = menu;

    this.idlingTexture.repeat.set(1 / CharacterAnimations.MovementIndexes, 1);
    this.idlingTexture.magFilter = THREE.NearestFilter;
    this.runningTexture.repeat.set(1 / CharacterAnimations.MovementIndexes, 1);
    this.runningTexture.magFilter = THREE.NearestFilter;
    this.readingTexture.repeat.set(1 / CharacterAnimations.ReadingIndexes, 1);
    this.readingTexture.magFilter = THREE.NearestFilter;

    this.setIdleAnimation();
    this.setCharacterMovementEventListeners();
    this.toggleMenu();
    unsubscribeNotifier$.subscribe(() => {
      clearInterval(this.idleTimer);
      clearInterval(this.movementInterval);
      clearInterval(this.animationInterval);
      this.playerYIndex$.complete();
    });
  }

  private setCharacterMovementEventListeners(): void {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      const direction = this.keyDirectionMap.get(event.key);
      if (direction && !this.isMenuOpen) {
        this.changeDirection(direction);
      }
    });

    document.addEventListener('keyup', (event: KeyboardEvent) => {
      const direction = this.keyDirectionMap.get(event.key);
      if (direction) {
        this.keyDownMap.set(direction, false);
        const oldDirection = Array.from(this.keyDownMap.entries()).find((entry) => entry[1]);
        if (oldDirection && oldDirection[1]) {
          this.changeDirection(oldDirection[0]);
        } else {
          this.isMoving = false;
          this.setIdleAnimation();
        }
      }
      if (event.key === "Shift") {
        this.isSprintToggled = !this.isSprintToggled;
        this.speed = this.isSprintToggled ? Speed.Run : Speed.Walk;
        if (this.isMoving) {
          this.setRunAnimation(false);
        }
      }
      if (event.key === "Enter") this.toggleMenu();
    });
  }

  private toggleMenu(): void {
    if (this.isMenuOpen) {
      this.menu.close();
    } else {
      this.isMoving = false;
      this.setIdleAnimation();
      this.menu.open(this.sprite.position.x, this.sprite.position.y);
    }
    this.isMenuOpen = !this.isMenuOpen;
  }

  private setIdleAnimation(): void {
    if (this.movementInterval) clearInterval(this.movementInterval);
    clearInterval(this.animationInterval);
    this.idlingTexture.offset.setX(CharacterAnimations.IdlingCycles[this.direction][0] / CharacterAnimations.MovementIndexes);
    this.sprite.material.map = this.idlingTexture;

    let tileIndexes: number[];
    switch (this.direction) {
      case Direction.Up:
        tileIndexes = CharacterAnimations.IdlingCycles.Up;
        break;
      case Direction.Down:
        tileIndexes = CharacterAnimations.IdlingCycles.Down;
        break;
      case Direction.Left:
        tileIndexes = CharacterAnimations.IdlingCycles.Left;
        break;
      case Direction.Right:
        tileIndexes = CharacterAnimations.IdlingCycles.Right;
        break;
    }

    let counter = 0;
    this.animationInterval = setInterval(() => {
      this.idlingTexture.offset.setX(tileIndexes[counter] / CharacterAnimations.MovementIndexes);
      ++counter;
      if (counter >= tileIndexes.length) {
        counter = 0;
      }
    }, 250);

    if (this.idleTimer) clearInterval(this.idleTimer);
    this.idleTimer = setInterval(() => {
      ++this.idleDuration;
      if (this.idleDuration >= 30) {
        this.setReadingAnimation();
      }
    }, 1000)
  }

  private setReadingAnimation(): void {
    clearInterval(this.animationInterval);
    clearInterval(this.idleTimer);
    this.sprite.material.map = this.readingTexture;

    let intervalCounter = 0;
    this.animationInterval = setInterval(() => {
      this.readingTexture.offset.setX(CharacterAnimations.ReadingCycles[intervalCounter] / CharacterAnimations.ReadingIndexes);
      ++intervalCounter;
      if (intervalCounter >= CharacterAnimations.ReadingCycles.length) {
        intervalCounter = 0;
      }
    }, 200);
  }

  private setRunAnimation(resetAnimationIndex = true): void {
    clearInterval(this.animationInterval);
    clearInterval(this.idleTimer);

    if (resetAnimationIndex) {
      this.currentRunningAnimationIndex = 0;
    }
    this.idleDuration = 0;
    this.runningTexture.offset.setX(CharacterAnimations.RunningCycles[this.direction][0] / CharacterAnimations.MovementIndexes);
    this.sprite.material.map = this.runningTexture;

    let tileIndexes: number[];
    switch (this.direction) {
      case Direction.Up:
        tileIndexes = CharacterAnimations.RunningCycles.Up;
        break;
      case Direction.Down:
        tileIndexes = CharacterAnimations.RunningCycles.Down;
        break;
      case Direction.Left:
        tileIndexes = CharacterAnimations.RunningCycles.Left;
        break;
      case Direction.Right:
        tileIndexes = CharacterAnimations.RunningCycles.Right;
        break;
    }

    this.animationInterval = setInterval(() => {
      this.runningTexture.offset.setX(tileIndexes[this.currentRunningAnimationIndex] / CharacterAnimations.MovementIndexes);
      ++this.currentRunningAnimationIndex;
      if (this.currentRunningAnimationIndex >= tileIndexes.length) {
        this.currentRunningAnimationIndex = 0;
      }
    }, this.isSprintToggled ? 80 : 120);
  }

  private changeDirection(direction: Direction): void {
    this.move(direction);
    this.keyDownMap.set(direction, true);
  }

  private move(direction: Direction): void {
    if (this.direction !== direction || !this.isMoving) {
      this.direction = direction;
      this.isMoving = true;
      this.setRunAnimation();
      clearInterval(this.movementInterval);
      this.movementInterval = setInterval(() => this.determineMovement(direction), 20);
    }
  }

  private moveCharacter(coordinate: Coordinate, position: number): void {
    if (coordinate === Coordinate.Y) this.moveCharacterY(position)
    if (coordinate === Coordinate.X) this.moveCharacterX(position)
  }

  private moveCharacterY(y: number): void {
    this.sprite.position.setY(y);
    this.camera.position.setY(y);
    this.interactionRange.move(Coordinate.Y, y);
    this.playerYIndex$.next(this.sprite.position.y);
  }

  private moveCharacterX(x: number): void {
    this.sprite.position.setX(x);
    this.camera.position.setX(x);
    this.interactionRange.move(Coordinate.X, x);
  }

  private determineMovement(direction: Direction): void {
    const coordinate = this.determineCoordinate(direction);
    const newPosition = this.newPosition(direction, this.sprite.position);
    this.collisionPlane.move(coordinate, newPosition);

    for (let i = 0; i < this.collisions.length; i++) {
      const collision = this.collisions[i];
      const collisionAction = () => this.collisionAction(collision, direction, coordinate);
      if (this.detectCollision(collision, direction, collisionAction, this.interactionAction)) {console.log('collision at ' + i)
        return;
      }console.log('no at ' + i)
    }
    console.log('moving character') //TODO: it doesn't detect a collision and moves the character every other rapid press of a direction
    this.moveCharacter(coordinate, newPosition);
  }

  private collisionAction(collision: CollisionPlane, direction: Direction, coordinate: Coordinate): boolean {
    this.collisionPlane.move(coordinate, collision.collisionEdge(direction) + this.collisionModifier(direction));
    clearInterval(this.movementInterval);
    return true;
  }

  private interactionAction() { console.log('something interactable in range') } //TODO: enable ability to press "Space" to interact with the thing in range
}
export default Player;