import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ActionItems.scss";

const ActionItems = (props: { onStaticPortfolioButtonClick: () => void }) => {

  const staticPortfolioButtonClickHandler = () => {
    props.onStaticPortfolioButtonClick();
  }

  function linkedInButtonClickHandler() {
    window.open('https://www.linkedin.com/in/brian-lowther/', '_blank', 'noopener,noreferrer');
  }

  return (
    <div className="action-items">
      <button className="personal-button linkedin" onClick={linkedInButtonClickHandler}>
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </button>
      <button className="website-portfolio-button" onClick={staticPortfolioButtonClickHandler}>
        Static <br /> Portfolio
      </button>
    </div>
  );
}

export default ActionItems;