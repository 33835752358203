import IsProduction from 'core/utils/IsProduction';
import { Scene } from 'three';
import { CollisionPlane } from '../Core/CollisionPlane';

const InitializeCollisions = (scene: Scene, npcs: CollisionPlane[], objects: CollisionPlane[]) => {
  const walls = {
    worldSouthBoundary: new CollisionPlane({ x: 0, y: .5, width: 100, height: 1 }),
    worldNorthBoundary: new CollisionPlane({ x: 0, y: 100.5, width: 100, height: 1 }),
    worldEastBoundary: new CollisionPlane({ x: -50.5, y: 50, width: 1, height: 100 }),
    worldWestBoundary: new CollisionPlane({ x: 50.5, y: 50, width: 1, height: 100 }),
    leftEntrance: new CollisionPlane({ x: -10.5, y: 7, width: 1, height: 14 }),
    rightEntrance: new CollisionPlane({ x: 10.5, y: 7, width: 1, height: 14 }),
    topEntrance: new CollisionPlane({ x: 0, y: 13, width: 22, height: 2 }),

    door: new CollisionPlane({ x: -6.5, y: 12, width: 7, height: 1 }),
    vendingMachine: new CollisionPlane({ x: 9, y: 12, width: 2, height: 1 }),
    boxes: new CollisionPlane({ x: 7, y: 11.5, width: 2, height: 1 }),
    desk: new CollisionPlane({ x: 0, y: 10.5, width: 6, height: 3 }),
    couch: new CollisionPlane({ x: 9.25, y: 4, width: 1.5, height: 4.25 })
  }

  const collisions = Object.values(walls).concat(npcs, objects);

  collisions.forEach(wall => {
    scene.add(wall.mesh);

    if (!IsProduction() && wall.visibilityMesh) {
      scene.add(wall.visibilityMesh);
    }
  });

  return collisions;
}

export default InitializeCollisions;
