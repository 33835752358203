import { Subject } from "rxjs";
import { Scene } from 'three';
import { CollisionPlane } from "../Core/CollisionPlane";
import { MapObject } from "../Core/MapObject";
import ObjectCategory from "../../../../core/enums/ObjectCategory";

const InitializeObjects = (scene: Scene, playerYIndex$: Subject<number>, unsubscribeNotifier$: Subject<void>): CollisionPlane[] => {
  const objects: MapObject[] = [];
  const collisions: CollisionPlane[] = [];

  objects.push(
    new MapObject({ category: ObjectCategory.Museum, id: 37, x: -6, y: 9, width: 2, height: 2, isCollidable: true }, playerYIndex$, unsubscribeNotifier$),
    new MapObject({ category: ObjectCategory.Museum, id: 37, x: -6, y: 5, width: 2, height: 2, isCollidable: true }, playerYIndex$, unsubscribeNotifier$),
    new MapObject({ category: ObjectCategory.Museum, id: 37, x: 0, y: 6, width: 2, height: 2, isCollidable: true }, playerYIndex$, unsubscribeNotifier$),
    new MapObject({ category: ObjectCategory.Museum, id: 37, x: 6, y: 5, width: 2, height: 2, isCollidable: true }, playerYIndex$, unsubscribeNotifier$),
    new MapObject({ category: ObjectCategory.Museum, id: 37, x: 6, y: 9, width: 2, height: 2, isCollidable: true }, playerYIndex$, unsubscribeNotifier$),
    new MapObject({ category: ObjectCategory.Tools, id: 'Javascript', x: -6, y: 10, width: 2, height: 2, yOffset: -1 }, playerYIndex$, unsubscribeNotifier$),
    new MapObject({ category: ObjectCategory.Tools, id: 'Typescript', x: -6, y: 6, width: 2, height: 2, yOffset: -1 }, playerYIndex$, unsubscribeNotifier$),
    new MapObject({ category: ObjectCategory.Tools, id: 'React', x: 0, y: 7, width: 2, height: 2, yOffset: -1 }, playerYIndex$, unsubscribeNotifier$),
    new MapObject({ category: ObjectCategory.Tools, id: 'Css', x: 6, y: 6, width: 2, height: 2, yOffset: -1 }, playerYIndex$, unsubscribeNotifier$),
    new MapObject({ category: ObjectCategory.Tools, id: 'Html', x: 6, y: 10, width: 2, height: 2, yOffset: -1 }, playerYIndex$, unsubscribeNotifier$)
  );

  objects.forEach(object => {
    scene.add(object.plane);
    if (object.collision) collisions.push(object.collision);
  });

  return collisions;

}

export default InitializeObjects;
